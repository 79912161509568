
import { graphql } from "gatsby"
import * as React from "react"
import FindDreamJob from "../../components/careers/finddreamjob"
import JobOpenings from "../../components/careers/jobopenings"
import JobSearchForm from "../../components/careers/jobsearchform"
import Locations from "../../components/careers/locations"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

export const query = graphql`
 query($language: String!) {
    allContentfulJobs(
      filter: { node_locale: { eq: "en-US" }}
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent 
        description {
          description
        }
        locations {
          id
          slug
          name
          shortName
        }
      }
    }
locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const LocationsPage = ({data}) => {
  const pageTitle = "Locations"
  const pageDescription = "Get to Know Our Locations"
  const lang = 'EN'
  const meta = []
  //const backgroundImage = "../../images/refer/bg-world@3x.png"

  const breadcrumbMap = [
    { title: "Careers", url: "/careers/" },
    { title: "Locations", url: "#" }
  ]

  var {
    nodes: jobs
  } = data.allContentfulJobs;

  const jobsList = jobs.map(({ jobSlug, title, locations, description, remote, relocation, office, urgent }) => (
    {
      title: title,
      jobLocation: locations.map((item) => (item["name"])).join(" / "),
      location: locations.map((item) => (item["shortName"])).join(" / "),
      url: "/careers/jobs/" + jobSlug + "/",
      urgent: urgent,
      tags: {"remote":remote,"office":office,"relocation":relocation},
      introduction: description ? description.description.slice(0,220) + "..." : ""
    }
  ));

  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap}>
        <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
        <div className="max-container-pages">
          <JobSearchForm></JobSearchForm>
          <Locations></Locations>
          <JobOpenings jobs={jobsList} search="All locations"></JobOpenings>
          <FindDreamJob></FindDreamJob>
        </div>
      </Layout>
    </>
  )
}

export default LocationsPage
