import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Container, Row } from "react-bootstrap"
import "./locations.scss"

const Locations = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <section className="section-locations-page">
            <Container className="section-wrap" fluid="xl">
              <h5 className="header-square green md-none">
                {t("Apply Now")}
              </h5>
              <h6>{t("Get to Know Our Locations")}</h6>
              <p>
                {t("Click a location to access the job opportunities page for this location")}
                If you do not see any opening that matches your experience,
                but would like to join our company, please send us your resume.
              </p>
              <h6 className="h-underline small">Americas</h6>
              <ul>
                <Row className="justify-content-center justify-content-md-start flex-gap">
                  <li key="usa">
                  <Link to="/careers/location-usa/">
                      <StaticImage
                        className=""
                        layout="fullWidth"
                        //height={580}
                        alt="USA"
                        src="../../images/locations/locations-USA@2x.webp"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Link>
                    <span>USA</span>
                    <Link to="/careers/location-usa/">Open Positions<span className="arrow-right"></span></Link>
                  </li>
                  <li key="mex">
                    <Link to="/careers/location-mexico/">
                      <StaticImage
                        className=""
                        layout="fullWidth"
                        //height={580}
                        alt="Mexico"
                        src="../../images/locations/locations-Mexico@2x.webp"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Link>
                    <span>Mexico</span>
                    <Link to="/careers/location-mexico/">Open Positions<span className="arrow-right"></span></Link>
                  </li>
                  <li key="arg">
                    <Link to="/careers/location-argentina/">
                      <StaticImage
                        className=""
                        layout="fullWidth"
                        //height={580}
                        alt="Argentina"
                        src="../../images/locations/locations-argentina.webp"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Link>
                    <span>Argentina</span>
                    <Link to="/careers/location-argentina/">Open Positions<span className="arrow-right"></span></Link>
                  </li>
                  <li key="uru">
                    <Link to="/careers/location-uruguay/">
                      <StaticImage
                        className=""
                        layout="fullWidth"
                        //height={580}
                        alt="Uruguay"
                        src="../../images/locations/locations-Uruguay@2x.webp"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Link>
                    <span>Uruguay</span>
                    <Link to="/careers/location-uruguay/">Open Positions<span className="arrow-right"></span></Link>
                  </li>
                  <li key="Per">
                    <Link to="/careers/location-peru/">
                      <StaticImage
                        className=""
                        layout="fullWidth"
                        //height={580}
                        alt="Peru"
                        src="../../images/locations/locations-peru@2x.webp"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Link>
                    <span>Peru</span>
                    <Link to="/careers/location-peru/">Open Positions<span className="arrow-right"></span></Link>
                  </li>
                  <li key="bol">
                    <Link to="/careers/location-bolivia/">
                      <StaticImage
                        className=""
                        layout="fullWidth"
                        //height={580}
                        alt="Bolivia"
                        src="../../images/locations/locations-bolivia@2x.webp"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Link>
                    <span>Bolivia</span>
                    <Link to="/careers/location-bolivia/">Open Positions<span className="arrow-right"></span></Link>
                  </li>
                </Row>
              </ul>
              <h6 className="h-underline small">EMEA</h6>
              <ul>
                <Row className="justify-content-center justify-content-md-start flex-gap">
                  <li key="spa">
                    <Link to="/careers/location-spain/">
                      <StaticImage
                        className=""
                        layout="fullWidth"
                        //height={580}
                        alt="Spain"
                        src="../../images/locations/locations-Spain@2x.webp"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Link>
                    <span>Spain</span>
                    <Link to="/careers/location-spain/">Open Positions<span className="arrow-right"></span></Link>
                  </li>
                  <li key="tun">
                    <Link to="/careers/location-tunisia/">
                      <StaticImage
                        className=""
                        layout="fullWidth"
                        //height={580}
                        alt="Tunisia"
                        src="../../images/locations/locations-Tunisia.webp"
                        formats={["auto", "webp", "avif"]}
                      />
                    </Link>
                    <span>Tunisia</span>
                    <Link to="/careers/location-tunisia/">Open Positions<span className="arrow-right"></span></Link>
                  </li>
                </Row>
              </ul>
            </Container>
          </section>
    </>
  )
}
export default Locations
